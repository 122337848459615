/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { anchorCustomerDocumentsRollback } from '../fn/entity-documents-controller/anchor-customer-documents-rollback';
import { AnchorCustomerDocumentsRollback$Params } from '../fn/entity-documents-controller/anchor-customer-documents-rollback';
import { anchorDocuments } from '../fn/entity-documents-controller/anchor-documents';
import { AnchorDocuments$Params } from '../fn/entity-documents-controller/anchor-documents';
import { anchorSvgDocuments } from '../fn/entity-documents-controller/anchor-svg-documents';
import { AnchorSvgDocuments$Params } from '../fn/entity-documents-controller/anchor-svg-documents';
import { deleteEntityDocumentByDownloadHash } from '../fn/entity-documents-controller/delete-entity-document-by-download-hash';
import { DeleteEntityDocumentByDownloadHash$Params } from '../fn/entity-documents-controller/delete-entity-document-by-download-hash';
import { deleteSecuredSvgDocumentByDownloadHash } from '../fn/entity-documents-controller/delete-secured-svg-document-by-download-hash';
import { DeleteSecuredSvgDocumentByDownloadHash$Params } from '../fn/entity-documents-controller/delete-secured-svg-document-by-download-hash';
import { deleteSvgDocumentByDownloadHash } from '../fn/entity-documents-controller/delete-svg-document-by-download-hash';
import { DeleteSvgDocumentByDownloadHash$Params } from '../fn/entity-documents-controller/delete-svg-document-by-download-hash';
import { Document } from '../models/document';
import { getDocumentForEntityByHash } from '../fn/entity-documents-controller/get-document-for-entity-by-hash';
import { GetDocumentForEntityByHash$Params } from '../fn/entity-documents-controller/get-document-for-entity-by-hash';
import { getEntityDocuments } from '../fn/entity-documents-controller/get-entity-documents';
import { GetEntityDocuments$Params } from '../fn/entity-documents-controller/get-entity-documents';
import { getSvgDocuments } from '../fn/entity-documents-controller/get-svg-documents';
import { GetSvgDocuments$Params } from '../fn/entity-documents-controller/get-svg-documents';
import { ResponseDocument } from '../models/response-document';
import { uploadCustomerOrVehicleFile } from '../fn/entity-documents-controller/upload-customer-or-vehicle-file';
import { UploadCustomerOrVehicleFile$Params } from '../fn/entity-documents-controller/upload-customer-or-vehicle-file';
import { uploadFileForSvgs } from '../fn/entity-documents-controller/upload-file-for-svgs';
import { UploadFileForSvgs$Params } from '../fn/entity-documents-controller/upload-file-for-svgs';
import { uploadSecuredFileForSvgs } from '../fn/entity-documents-controller/upload-secured-file-for-svgs';
import { UploadSecuredFileForSvgs$Params } from '../fn/entity-documents-controller/upload-secured-file-for-svgs';
import { uploadSecuredSvgFile } from '../fn/entity-documents-controller/upload-secured-svg-file';
import { UploadSecuredSvgFile$Params } from '../fn/entity-documents-controller/upload-secured-svg-file';
import { uploadSvgFile } from '../fn/entity-documents-controller/upload-svg-file';
import { UploadSvgFile$Params } from '../fn/entity-documents-controller/upload-svg-file';

@Injectable({ providedIn: 'root' })
export class EntityDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `anchorCustomerDocumentsRollback()` */
  static readonly AnchorCustomerDocumentsRollbackPath = '/{id}/documents/anchorRollback';

  /**
   * from real path back to tmp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anchorCustomerDocumentsRollback()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  anchorCustomerDocumentsRollback$Response(params: AnchorCustomerDocumentsRollback$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return anchorCustomerDocumentsRollback(this.http, this.rootUrl, params, context);
  }

  /**
   * from real path back to tmp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anchorCustomerDocumentsRollback$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  anchorCustomerDocumentsRollback(params: AnchorCustomerDocumentsRollback$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.anchorCustomerDocumentsRollback$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `anchorDocuments()` */
  static readonly AnchorDocumentsPath = '/{entityType}/{id}/documents/anchor';

  /**
   * anchor a temp document in its real context of the entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anchorDocuments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  anchorDocuments$Response(params: AnchorDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return anchorDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * anchor a temp document in its real context of the entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anchorDocuments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  anchorDocuments(params: AnchorDocuments$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.anchorDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `anchorSvgDocuments()` */
  static readonly AnchorSvgDocumentsPath = '/svgs/{id}/documents/anchor';

  /**
   * anchor a temp document in its real context of the svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anchorSvgDocuments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  anchorSvgDocuments$Response(params: AnchorSvgDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return anchorSvgDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * anchor a temp document in its real context of the svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anchorSvgDocuments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  anchorSvgDocuments(params: AnchorSvgDocuments$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.anchorSvgDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `uploadCustomerOrVehicleFile()` */
  static readonly UploadCustomerOrVehicleFilePath = '/{entityType}/{id}/documents/upload';

  /**
   * upload a document in context of an entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCustomerOrVehicleFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerOrVehicleFile$Response(params: UploadCustomerOrVehicleFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Document>> {
    return uploadCustomerOrVehicleFile(this.http, this.rootUrl, params, context);
  }

  /**
   * upload a document in context of an entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadCustomerOrVehicleFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerOrVehicleFile(params: UploadCustomerOrVehicleFile$Params, context?: HttpContext): Observable<Document> {
    return this.uploadCustomerOrVehicleFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Document>): Document => r.body)
    );
  }

  /** Path part for operation `uploadSecuredSvgFile()` */
  static readonly UploadSecuredSvgFilePath = '/svgs/{id}/secured-documents/upload';

  /**
   * upload a secured document in context of an svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadSecuredSvgFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSecuredSvgFile$Response(params: UploadSecuredSvgFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Document>> {
    return uploadSecuredSvgFile(this.http, this.rootUrl, params, context);
  }

  /**
   * upload a secured document in context of an svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadSecuredSvgFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSecuredSvgFile(params: UploadSecuredSvgFile$Params, context?: HttpContext): Observable<Document> {
    return this.uploadSecuredSvgFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Document>): Document => r.body)
    );
  }

  /** Path part for operation `uploadSvgFile()` */
  static readonly UploadSvgFilePath = '/svgs/{id}/documents/upload';

  /**
   * upload a document in context of an svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadSvgFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSvgFile$Response(params: UploadSvgFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Document>> {
    return uploadSvgFile(this.http, this.rootUrl, params, context);
  }

  /**
   * upload a document in context of an svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadSvgFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSvgFile(params: UploadSvgFile$Params, context?: HttpContext): Observable<Document> {
    return this.uploadSvgFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Document>): Document => r.body)
    );
  }

  /** Path part for operation `uploadSecuredFileForSvgs()` */
  static readonly UploadSecuredFileForSvgsPath = '/svgs/secured-documents/upload';

  /**
   * upload a secured document in context of an svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadSecuredFileForSvgs()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSecuredFileForSvgs$Response(params?: UploadSecuredFileForSvgs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return uploadSecuredFileForSvgs(this.http, this.rootUrl, params, context);
  }

  /**
   * upload a secured document in context of an svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadSecuredFileForSvgs$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSecuredFileForSvgs(params?: UploadSecuredFileForSvgs$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.uploadSecuredFileForSvgs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `uploadFileForSvgs()` */
  static readonly UploadFileForSvgsPath = '/svgs/documents/upload';

  /**
   * upload a document in context of an svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFileForSvgs()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFileForSvgs$Response(params?: UploadFileForSvgs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Document>>> {
    return uploadFileForSvgs(this.http, this.rootUrl, params, context);
  }

  /**
   * upload a document in context of an svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFileForSvgs$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFileForSvgs(params?: UploadFileForSvgs$Params, context?: HttpContext): Observable<Array<Document>> {
    return this.uploadFileForSvgs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Document>>): Array<Document> => r.body)
    );
  }

  /** Path part for operation `getEntityDocuments()` */
  static readonly GetEntityDocumentsPath = '/{entityType}/{id}/documents';

  /**
   * a list of documents for the requested entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntityDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntityDocuments$Response(params: GetEntityDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
    return getEntityDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of documents for the requested entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntityDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntityDocuments(params: GetEntityDocuments$Params, context?: HttpContext): Observable<ResponseDocument> {
    return this.getEntityDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocument>): ResponseDocument => r.body)
    );
  }

  /** Path part for operation `getDocumentForEntityByHash()` */
  static readonly GetDocumentForEntityByHashPath = '/{entityType}/{id}/documents/{downloadHash}';

  /**
   * starts the download stream of a documents in specific entity context
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentForEntityByHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentForEntityByHash$Response(params: GetDocumentForEntityByHash$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getDocumentForEntityByHash(this.http, this.rootUrl, params, context);
  }

  /**
   * starts the download stream of a documents in specific entity context
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentForEntityByHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentForEntityByHash(params: GetDocumentForEntityByHash$Params, context?: HttpContext): Observable<Blob> {
    return this.getDocumentForEntityByHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `deleteEntityDocumentByDownloadHash()` */
  static readonly DeleteEntityDocumentByDownloadHashPath = '/{entityType}/{id}/documents/{downloadHash}';

  /**
   * delete a document from entity context.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntityDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntityDocumentByDownloadHash$Response(params: DeleteEntityDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEntityDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a document from entity context.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEntityDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntityDocumentByDownloadHash(params: DeleteEntityDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteEntityDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSvgDocuments()` */
  static readonly GetSvgDocumentsPath = '/svgs/{id}/documents';

  /**
   * a list of documents for the requested svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgDocuments$Response(params: GetSvgDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocument>> {
    return getSvgDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * a list of documents for the requested svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgDocuments(params: GetSvgDocuments$Params, context?: HttpContext): Observable<ResponseDocument> {
    return this.getSvgDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocument>): ResponseDocument => r.body)
    );
  }

  /** Path part for operation `deleteSecuredSvgDocumentByDownloadHash()` */
  static readonly DeleteSecuredSvgDocumentByDownloadHashPath = '/svgs/{id}/secured-documents/{downloadHash}';

  /**
   * delete a secured document from svg entity context.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSecuredSvgDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSecuredSvgDocumentByDownloadHash$Response(params: DeleteSecuredSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSecuredSvgDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a secured document from svg entity context.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSecuredSvgDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSecuredSvgDocumentByDownloadHash(params: DeleteSecuredSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteSecuredSvgDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteSvgDocumentByDownloadHash()` */
  static readonly DeleteSvgDocumentByDownloadHashPath = '/svgs/{id}/documents/{downloadHash}';

  /**
   * delete a document from entity context.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSvgDocumentByDownloadHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgDocumentByDownloadHash$Response(params: DeleteSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSvgDocumentByDownloadHash(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a document from entity context.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSvgDocumentByDownloadHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgDocumentByDownloadHash(params: DeleteSvgDocumentByDownloadHash$Params, context?: HttpContext): Observable<void> {
    return this.deleteSvgDocumentByDownloadHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
