import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AsiSvgInfoDto } from '@myasi/api/customers/core';

import { SvgInfoRepositoryService } from '../../repositories/customers/svg-info-repository.service';
import { RepositoryStoreService } from '../repository-store.service';

@Injectable({ providedIn: 'root' })
export class SvgInfoStoreService extends RepositoryStoreService<AsiSvgInfoDto[]> {
	constructor(private svgInfoRepositoryService: SvgInfoRepositoryService) {
		super();
	}

	fetchData(): Observable<AsiSvgInfoDto[]> {
		return this.svgInfoRepositoryService.getAllSvgInfos();
	}
}
