import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AsiSvgInfoDto, SvgInfoControllerService } from '@myasi/api/customers/svginfo';

@Injectable({ providedIn: 'root' })
export class SvgInfoRepositoryService {
	constructor(private svgInfoControllerService: SvgInfoControllerService) {}

	getAllSvgInfos(): Observable<AsiSvgInfoDto[]> {
		return this.svgInfoControllerService.getAllSvgInfos();
	}
}
