import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import * as IBAN from 'ibantools';

import { isDefined, parseFloatFromString } from '@mysvg/utils';
import { CONTROL_ERROR_KEYS, ErrorMessageKey } from '@svg-frontends/error';

export class CstmValidators {
	private static readonly buyerReferencePattern = /^(0[1-9]|1[0-6]|99)?([0-9]{1,10})-?([A-Z0-9]{1,30})?-([0-9]){2}$/;
	private static readonly containsLowerCaseLetterPattern = /[a-z]+/;
	private static readonly containsNumberPattern = /[0-9]+/;
	private static readonly containsUpperCaseLetterPattern = /[A-Z]+/;
	private static readonly lettersAndNumbersOnlyPattern = /^[A-zäöüÄÖÜß0-9]+$/;
	private static readonly licensePlatePatterns = {
		DE: /^[A-ZÖÜÄ]{1,3} 0[3-7][0-9]{1,7}[EH]?$|^[A-ZÖÜÄ]{1,3} [A-Z]{1,2} [1-9][0-9]{0,3}[EH]?$|^[A-ZÖÜÄ]{1,3} [1-9][0-9]{0,3} [A-Z]?$/,
	};
	private static readonly releaseNoteVersionHeaderPattern = /^(Release|Hotfix)\s\d+\.\d+\.\d+$/;
	private static readonly rvNumberPattern = /^[W]([0-9]{8})$/;
	private static readonly vatNumberPattern = /^[A-Z]{2}[0-9]{9}$/;

	/**
	 * Validates Vehicle Identification Number (FIN): must be 17 characters long, contain only allowed characters [1234567890 ABCDEFGH JKLMN P RSTUVWXYZ] (no I,O,Q)
	 */
	private static readonly vinPattern = /^[A-HJ-NPR-Z0-9]{17}$/;

	static required(errorKey = 'required'): ValidatorFn {
		return (control): { [key: string]: any } => {
			const result: ValidationErrors | null = Validators.required(control);

			if (result === null) {
				return null;
			} else {
				return { [errorKey]: control.value };
			}
		};
	}

	/**
	 * [NOTE] deprecated validator - replace, which is on form group instead of being on form control
	 * 				use `licensePlateControl` validator instead
	 */
	static licensePlate(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const group = control as FormGroup;
			const nation = group.controls['licensePlateNation'];
			const plate = group.controls['licensePlate'];

			if (!nation || !plate || (nation.value !== 'DE' && nation.value !== 'Deutschland')) {
				return null;
			}

			const value = plate.value as string;
			const pattern = CstmValidators.licensePlatePatterns[nation.value];

			if (!pattern || !value) {
				return null;
			}

			return value.match(pattern) ? null : { cstmLicensePlate: value };
		};
	}

	/**
	 * [CAUTION] this validator requires a control with license plate nation in same form group
	 */
	static licensePlateControl(licensePlateNationControlName: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value as string;
			const nationControl: AbstractControl = control.parent ? control.parent.controls[licensePlateNationControlName] : null;
			const nationValue = nationControl ? nationControl.value : null;
			const pattern = CstmValidators.licensePlatePatterns[nationValue];

			if (!nationValue || !value || !pattern) {
				return null;
			} else {
				return value.match(pattern) ? null : { cstmLicensePlate: value };
			}
		};
	}

	static rvNumber(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			return !value || value.toString().match(CstmValidators.rvNumberPattern) ? null : { cstmRvNumber: value };
		};
	}

	static buyerReference(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			return !value || value.toString().match(CstmValidators.buyerReferencePattern)
				? null
				: { [ErrorMessageKey.CSTM_BUYER_REFERENCE_FORMAT]: value };
		};
	}

	static crefoPassword(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			let errors = {};

			if (value) {
				if (!value.match(CstmValidators.containsNumberPattern)) {
					errors = { ...errors, cstmPatternContainsNumber: value };
				} else if (!value.match(CstmValidators.containsLowerCaseLetterPattern)) {
					errors = { ...errors, cstmPatternContainsLowerCaseLetter: value };
				} else if (!value.match(CstmValidators.containsUpperCaseLetterPattern)) {
					errors = { ...errors, cstmPatternContainsUpperCaseLetter: value };
				}
			}

			return Object.keys(errors).length === 0 ? null : errors;
		};
	}

	static upperCaseOnly(): ValidatorFn {
		return (control): { [key: string]: any } => {
			const value = control.value;
			const isUpperCaseOnly = !!value && value === value.toUpperCase();
			return !value || isUpperCaseOnly ? null : { cstmFormat: value };
		};
	}

	static svgCustomerNumber(): ValidatorFn {
		return (control): { [key: string]: any } => {
			const value = control.value;

			const isNumeric = CstmValidators.isValueNumeric(control);
			const isCorrectLength = CstmValidators.isCorrectLength(control, 7);

			return !value || (isNumeric && isCorrectLength) ? null : { cstmSvgCustomerNumber: value };
		};
	}

	/**
	 * [CAUTION] this validator ignores whitespaces
	 */
	static lettersAndNumbersOnly(): ValidatorFn {
		return (control): { [key: string]: any } => {
			const value = control.value;
			const isLettersAndNumbersOnly = !!value && value.replace(/\s/g, '').match(CstmValidators.lettersAndNumbersOnlyPattern);
			return !value || isLettersAndNumbersOnly ? null : { cstmFormat: value };
		};
	}

	// no error if no maxLoad is set
	// maxLoad not more than 999 000 kg / 999 t
	static maxLoad(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			if (!value || value === '') {
				// no value no error
				return null;
			} else {
				const valueAsNumber = Number(value);
				const valid = !isNaN(valueAsNumber) && valueAsNumber < 1000000;
				return valid ? null : { cstmMaxLoad: value };
			}
		};
	}

	static isPhone(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;

			if (!value) {
				return null;
			} else {
				const pattern = /^\+[1-9]\d{3,14}$/g;
				return value.match(pattern) ? null : { cstmPhone: value };
			}
		};
	}

	/**
	 * this validator is used to test valid uri address
	 * these are valid match website uri
	 *  "http://www.test.de", "https://www.test.de","https://www.test.de/page",
	 *  and these are invalid match website uri
	 *	"http://www.t est.de", "http://www.test.d", "ftp://www.test.com",
	 *  "https://www.svg-stuttgart.de/testseiteganzweitunten"
	 *
	 */
	static isWebsite(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			if (!value) {
				return null;
			} else {
				const pattern = '^((http|https)://)?(www\\.)?([a-zA-Z0-9\\-]+)\\.([a-zA-Z]{2,5})(:\\d+)?(/([a-zA-Z0-9\\-]+))?$';
				return value.match(pattern) ? null : { cstmWebsite: value };
			}
		};
	}

	static isInteger(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			if (!value || value === '') {
				return null;
			} // no value no error
			const isString = typeof value === 'string' || value instanceof String;
			let isInt;

			if (isString) {
				isInt = value.match(/^[0-9]*$/);
			} else {
				isInt = !isNaN(Number(value));
			}

			return isInt ? null : { cstmIsInteger: value };
		};
	}

	static limitAt(limit: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			const valAsNumber = Number(value);
			return !(valAsNumber > limit) ? null : { cstmIsLimited: value };
		};
	}

	static isNotEmptyArray(errorKey = 'cstmInputRoles'): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			if (!value) {
				// no value no error
				return null;
			} else {
				const isNotEmptyArray = Array.isArray(value) && value.length > 0;
				return isNotEmptyArray ? null : { [errorKey]: value };
			}
		};
	}

	static isIban(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			if (!value) {
				// no value no error
				return null;
			} else {
				const electronicFormat = IBAN.electronicFormatIBAN(value);
				const isValidIban = IBAN.isValidIBAN(electronicFormat);
				return isValidIban ? null : { cstmIbanError: electronicFormat };
			}
		};
	}

	static isBIC(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			if (!value) {
				// no value no error
				return null;
			} else {
				const isValidBic = IBAN.isValidBIC(value) && value === value.toUpperCase();
				return isValidBic ? null : { cstmBicError: value };
			}
		};
	}

	static areAllItemsValid(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const group = control as FormGroup;
			const invalidControl = Object.values(group.controls).find((innerControl: AbstractControl) => innerControl.status === 'INVALID');

			return !invalidControl ? null : invalidControl.errors;
		};
	}

	static hasAtLeastOneModifiedValue(errorKey = 'cstmAtLeastOneValueChangeRequired'): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const group = control as FormGroup;

			const hasAtLeastOneModValue = Object.values(group.controls).some(
				(innerControl: AbstractControl) => innerControl.value !== null && innerControl.value !== '',
			);

			return hasAtLeastOneModValue ? null : { [errorKey]: group.value };
		};
	}

	static isNonZero(errorKey = 'cstmInputLicensePlates'): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value: number = control.value;
			if (value > 0) {
				// no value no error
				return null;
			} else {
				return { [errorKey]: value };
			}
		};
	}

	static isRequired(errorKey: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;

			if (value) {
				// no value no error
				return null;
			} else {
				return { [errorKey]: value };
			}
		};
	}

	static eetsPoland(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value: string[] = control.value;

			if (!!value && value.length > 0 && value.indexOf('PL') !== -1 && value.indexOf('FR') === -1) {
				return { ['cstmEetsPoland']: value };
			} else {
				return null;
			}
		};
	}

	static eetsPortugal(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value: string[] = control.value;

			if (!!value && value.length > 0 && value.indexOf('PT') !== -1 && value.indexOf('ES') === -1) {
				return { ['cstmEetsPortugal']: value };
			} else {
				return null;
			}
		};
	}

	static eetsItaly(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value: string[] = control.value;

			if (!!value && value.length === 1 && value[0] === 'IT') {
				return { ['cstmEetsItaly']: value };
			} else {
				return null;
			}
		};
	}

	static releaseNotesVersionHeader(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: string } => {
			const value = control.value;
			return !value || value.toString().match(CstmValidators.releaseNoteVersionHeaderPattern)
				? null
				: { cstmReleaseNoteHeaderInvalid: value };
		};
	}

	private static isValueNumeric(control: AbstractControl): boolean {
		const value = control.value;
		const noValue = !value || value === '';
		const pattern = /^-?(0|([1-9]\d*))([,]?[0-9]+)?$/g;
		return !noValue ? !!value.toString().match(pattern) : false;
	}

	private static isCorrectLength(control: AbstractControl, len: number): boolean {
		const value = control.value;
		const noValue = !value || value === '';
		return !noValue && value.length ? value.length === len : false;
	}

	/**
	 * [NOTE] month are zero indexed (https://day.js.org/docs/en/get-set/month)
	 */
	static isNotLastYearExceptForMonthJanuary(): ValidatorFn {
		return (group: AbstractControl): { [key: string]: any } => {
			const value = group.value;

			if (!value) {
				return null;
			} else {
				const date = dayjs(value);
				const isSameYear = date.year() === dayjs().year();
				const nowIsJanuary = dayjs().month() === 0; // january is month 0
				const dateIsOneYearBehind = date.add(1, 'year').year() === dayjs().year();
				const isLastYearAndJanuary = nowIsJanuary && dateIsOneYearBehind;
				return isSameYear || isLastYearAndJanuary ? null : { cstmNotLastYearExceptItsJanuary: value };
			}
		};
	}

	static atLeastOneCheckBox(exceptThisKeys: string[] = []): ValidatorFn {
		return (group: AbstractControl): { [key: string]: any } => {
			const value = group.value;

			if (!value) {
				return null;
			} else {
				const isAtLeastOneSet = Object.keys(value)
					.filter((key: string) => exceptThisKeys.every((blacklisted: string) => blacklisted !== key))
					.map((key: string) => !!value[key])
					.some((isSet: boolean) => isSet);
				return isAtLeastOneSet ? null : { cstmMissingCheckBox: value };
			}
		};
	}

	static minOnNumberString(min: number): ValidatorFn {
		return (group: AbstractControl): { [key: string]: any } => {
			const value: any = group.value;

			if (!value) {
				return null;
			} else {
				const isEqualsOrHigher = min <= parseFloatFromString(value);
				return isEqualsOrHigher ? null : { [CONTROL_ERROR_KEYS.MIN]: { min, actual: value } };
			}
		};
	}

	static maxOnNumberString(max: number): ValidatorFn {
		return (group: AbstractControl): { [key: string]: any } => {
			const value = group.value;

			if (!value) {
				return null;
			} else {
				const isMax = parseFloatFromString(value) <= max;
				return isMax ? null : { [CONTROL_ERROR_KEYS.MAX]: { max, actual: value } };
			}
		};
	}

	static mustNotMatch(blacklistValues: string[], errorKey: string = 'cstmMustNotMatch'): ValidatorFn {
		return (group: AbstractControl): { [key: string]: any } => {
			const value = group.value;

			if (!value) {
				return null;
			} else {
				const foundMatch = blacklistValues.some((entry: string) => entry === value?.toString());
				return !foundMatch ? null : { [errorKey]: value };
			}
		};
	}

	// no error if no date is set
	static dateMustBeBefore(start: string): ValidatorFn {
		return (dateControl: AbstractControl): { [key: string]: any } => {
			// check if date valid
			const value = dateControl.value;
			const noValue = !value || value === '';
			const isBefore = dayjs(value).isSameOrBefore(dayjs(start));
			return noValue || isBefore ? null : { cstmDateMustBeBefore: start };
		};
	}

	static dateMustNotBeforeOneOf(customerRegistrationDates: string[]): ValidatorFn {
		return (dateControl: AbstractControl): { [key: string]: any } => {
			// check if date valid
			const value = dateControl.value;
			const noValue = !value || value === '';
			// null checks
			customerRegistrationDates = customerRegistrationDates || [];
			const dateAfterValue = customerRegistrationDates
				.filter((registrationDate: string) => isDefined(registrationDate))
				.find((registrationDate: string) => dayjs(value).isAfter(dayjs(registrationDate)));
			return noValue || !dateAfterValue ? null : { cstmDateMustBeBefore: dateAfterValue };
		};
	}

	static vatMustMatchPattern(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: string } => {
			const value = control.value;
			return !value || value.toString().match(CstmValidators.vatNumberPattern) ? null : { cstmVatNumberPattern: value };
		};
	}

	static vinValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: string | number } => {
			const value = control.value;

			if (!value) {
				// empty or untouched is okay
				// required should be done via required validator
				return null;
			}

			// Check length
			if (value.length !== 17) {
				// return wanted length as value for error message
				return { invalidLength: 17 };
			}

			// Check pattern match
			return !value.match(CstmValidators.vinPattern) ? { cstmVinInvalidCharacters: value } : null;
		};
	}
}
