// formerly NG_CONTROL_ERROR_KEYS
export enum CONTROL_ERROR_KEYS {
	EMAIL = 'email',
	MAX = 'max',
	MAX_LENGTH = 'maxlength',
	MIN = 'min',
	MIN_LENGTH = 'minlength',
	PATTERN = 'pattern',
	REQUIRED = 'required',
	INVALID_LENGTH = 'invalidLength',

	// formerly MAC_CONTROL_ERROR_KEYS
	DATE = 'Date',
	END_DATE = 'EndDate',
	IS_MAIL = 'IsMail',
	MAIL_CONTAINS_WHITESPACES = 'MailContainsWhitespaces',
	IS_NUMBER = 'IsNumber',
	MAX_DECIMAL_PLACES = 'MaxDecimalPlaces',
	MAXIMUM_FILE_SIZE_EXCEEDED = 'MaximumFileSizeExceeded',
	MIN_DECIMAL_PLACES = 'MinDecimalPlaces',
	NOT_ALLOWED_FILE_TYPES = 'NotAllowedFileTypes',
	PATTERN_ALPHA_NUMERIC = 'PatternAlphaNumeric',
	VALUE_BIGGER_OR_EQUALS = 'ValueBiggerOrEquals',
	VALUE_EQUALS = 'ValueEquals',
	VALUE_SMALLER_OR_EQUALS = 'ValueSmallerOrEquals',

	// formerly strings in ControlErrorFactoryService
	OWL_DATE_MIN = 'owlDateTimeMin',
	OWL_DATE_MAX = 'owlDateTimeMax',
	CSTM_DATE_MUST_BEFORE = 'cstmDateMustBeBefore',
	CSTM_VAT_NUMBER_PATTERN = 'cstmVatNumberPattern',
	CSTM_VIN_CHARACTERS = 'cstmVinInvalidCharacters',
}

export const TRANSLATED_ERROR_MESSAGE_KEY = 'TranslatedErrorMessageKey';
