import { Injectable } from '@angular/core';

import { ErrorMessageKey } from '../../enums/error-message-key';
import { SafetyErrorType } from '../../enums/safety-error-type.enum';
import {
	ENRICHED_ERROR_PLACEHOLDER,
	EnrichedErrorMessagesMapping,
	ErrorMessageFactoryService,
	ErrorMessagesMapping,
} from './error-message-factory.service';

export const DEFAULT_ERROR_MESSAGE = 'Ein unerwarteter Fehler ist aufgetreten, bitte kontaktieren Sie Ihren Ansprechpartner.';

const MESSAGES: ErrorMessagesMapping = {
	cstmAtLeastOneValueChangeRequired: 'Bitte geben Sie mindestens einen neuen Wert ein.',
	cstmBicError: 'Bitte geben Sie einen validen BIC an.',
	cstmChooseAVehicle: 'Bitte wählen Sie ein Tauschfahrzeug aus.',
	cstmConditionInvalid: 'Der Betrag der Kondition ist nicht valide oder Sie konnte nicht validiert werden.',
	cstmActivationDateInvalid: 'Der Wert des Aktivierungsdatums ist nicht valide oder es konnte nicht validiert werden.',
	cstmExpirationDateInvalid: 'Der Wert des Ablaufdatums ist nicht valide oder es konnte nicht validiert werden.',
	cstmCustomerNumberConflict: 'Die Kundennummer ist bereits vergeben.',
	cstmCustomerNumberInvalid: 'Die Kundennummer ist ungültig.',
	cstmDate: 'Bitte geben Sie ein valides Datum ein. Verwenden Sie das Datums-Auswahlfeld.',
	cstmDefaultConditionLoading: 'Die Standard Konditionen konnten nicht geladen werden, bitte versuchen Sie es erneut.',
	cstmEetsItaly: 'Italien kann nur in Kombination mit einem anderen Land ausgewählt werden!',
	cstmEetsPoland: 'Polen kann nur in Kombination mit Frankreich ausgewählt werden!',
	cstmEetsPortugal: 'Portugal kann nur in Kombination mit Spanien ausgewählt werden!',
	cstmEndDate: 'Das Datum darf nicht vor dem Startdatum sein!',
	cstmFileUpload: 'Bitte laden Sie ein Dokument hoch.',
	cstmFinancialType: 'Bitte wählen Sie einen Finanzierungs-Typ aus.',
	cstmFormat: 'Das eingegebene Format ist nicht valide. Bitte korrigieren Sie Ihre Eingabe.',
	cstmFuelcardIdConflict: 'Die Tankkartennummer ist bereits vergeben.',
	cstmFuelcardIdInvalid: 'Die Tankkartennummer ist ungültig.',
	cstmIbanError: 'Bitte geben Sie einen validen IBAN an.',
	cstmInputBoxTypes: 'Bitte wählen Sie mindestens einen Boxtypen aus.',
	cstmMultiLevelSelect: 'Bitte wählen Sie mindestens eine Rolle aus.',
	cstmMultiSvgSelect: 'Bitte wählen Sie mindestens eine SVG aus.',
	cstmInputCountries: 'Bitte wählen Sie mindestens ein Land aus',
	cstmInputLicensePlates: 'Bitte geben Sie mindestens ein Fahrzeugkennzeichen ein',
	cstmInputRoles: 'Bitte wählen Sie mindestens eine Rolle aus.',
	cstmIsInteger: 'Bitte geben Sie eine ganze Zahl ein. Keine Punkte, Kommas oder Leerzeichen.',
	cstmIsLimited: 'Der eingegebene Wert ist zu groß oder keine Zahl.',
	cstmIsMail: 'Bitte geben Sie eine valide Email Adresse ein!',
	cstmIsNumber: 'Das eingegebene Zahlen-Format ist nicht korrekt.',
	cstmJsonParseError: 'Bitte geben Sie eine valide JSON String an.',
	cstmLicensePlate: 'Bitte geben Sie ein valides Kennzeichen ein. Beispiel: `F XX 123`, `F 05123`, `F XX 123E` oder `HH 45 A`',
	cstmDefaultOverdraftLimitedButMissingLimit: 'Limitierte Kategorie benötigt einen Maximalwert',
	[ErrorMessageKey.CSTM_BUYER_REFERENCE_FORMAT]: 'Bitte geben Sie eine valide Leitweg-ID ein.',
	cstmMaxLoad: 'Das zulässige Gesamtgewicht darf maximal 6 Stellen haben.',
	cstmPhone:
		"Bitte geben Sie eine valide Telefonnummer ein. Verwenden Sie keine Leerzeichen oder Sondernzeichen und beginnen Sie mit der internationalen Vorwahl (für Deutschland die '+49')",
	[ErrorMessageKey.CSTM_REQUIRED_CITY]: 'Bitte geben Sie den Hauptsitz Ihres Unternehmens ein.',
	cstmMaxDecimalPlaces: 'Zu viele Nachkommastellen',
	cstmMinDecimalPlaces: 'Zu wenige Nachkommastellen',
	cstmPatternAlphaNumeric: 'Die Eingabe muss alphanumerisch sein',
	cstmPatternContainsLowerCaseLetter: 'Die Eingabe muss mind. einen Kleinbuchstaben enthalten',
	cstmPatternContainsNumber: 'Die Eingabe muss mindestens eine Ziffer enthalten',
	cstmPatternContainsUpperCaseLetter: 'Die Eingabe muss mind. einen Großbuchstaben enthalten',
	[ErrorMessageKey.CSTM_REQUIRED_COMPANY]: 'Bitte geben Sie den Firmennamen ein.',
	[ErrorMessageKey.CSTM_REQUIRED_EMAIL]: 'Bitte geben Sie die E-Mail-Adresse ein, die in Ihrem SVG-Kundenkonto eingetragen ist.',
	[ErrorMessageKey.CSTM_REQUIRED_STREET]: 'Bitte geben Sie Ihre Straße und Hausnummer ein',
	[ErrorMessageKey.CSTM_REQUIRED_ZIP]: 'Bitte geben Sie den Hauptsitz Ihres Unternehmens ein.',
	cstmRvNumber: 'Eine Nummer muss dem Format W12345678 entsprechen',
	cstmStartDate: 'Bitte einen gültigen Zeitraum angeben!',
	cstmSvgCustomerNumber: 'Bitte geben Sie Ihre 7-stellige Kundennummer ein.',
	cstmURL: 'Bitte eine gültige URL eingeben.',
	cstmValueBiggerOrEquals: 'Der eingebene Wert ist zu klein.',
	cstmValueEquals: 'Die Passwörter stimmen nicht überein.',
	cstmValueSmallerOrEquals: 'Der eingebene Wert ist zu groß.',
	cstmVinInvalid: 'Die eingegebene Fin ist inkorrekt.',
	cstmZipInvalid: 'Die angegebene PLZ konnte nicht aufgelöst werden.',
	cstmZipUnvalidated: 'Bitte validieren Sie die PLZ.',
	cstmBankDetailsConflict: 'Es gibt bereits eine aktive Bankdverbindung mit der gewünschten Verwendung',
	cstmReleaseNoteHeaderInvalid: 'Die Überschrift ist ungültig. Sie muss dem Muster "Release x.x.x" oder "Hotfix x.x.x" entsprechen.',
	cstmAxlesNoMatch:
		'Die Vignette Achszahl darf nicht kleiner sein, als die Achszahl Zugmaschine, die der Achszahl aus dem Fahrzeugschein entsprechen soll.',
	'Internal Server Error': 'Der Server konnte die Anfrage nicht bearbeiten. Bitte versuchen Sie es später noch einmal.',
	'no valid license plate': 'Das eingegebene Kennzeichen ist nicht valide! Bitte versuchen Sie es erneut.',
	'vehicle.delete.activeVignettesFound': 'Das Fahrzeug kann nicht gelöscht werden. Es wurden aktive Vignette für dieses Fahrzeug gefunden.',
	'conflict: vehicle.upsert.alredyExisting': 'Es existiert bereits ein aktives Fahrzeug mit dem angegebenen Kennzeichen.',
	0: 'Ein unerwarteter Fehler ist aufgetreten. Bitte prüfen Sie Ihre Internetverbindung oder kontaktieren Sie Ihren Ansprechpartner.',
	1014: 'Ihr Browser unterstützt nicht die Storage Api, bitte kontaktieren Sie Ihren Ansprechpartner',
	22: 'Ihr Browser unterstützt nicht die Storage Api, bitte kontaktieren Sie Ihren Ansprechpartner',
	400: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Ansprechpartner!',
	401: 'Sie sind nicht berechtigt diese Aktion auszuführen.',
	403: 'Sie sind nicht berechtigt, diese Inhalte zu laden. Bitte kontaktieren Sie Ihren Ansprechpartner oder Administrator.',
	404: 'Die Resource, die Sie aufrufen wollten, existiert nicht. Bitte kontaktieren Sie Ihren Ansprechpartner oder Administrator.',
	406: 'Die Eingabe wird vom Server nicht akzeptiert. Bitte kontaktieren Sie Ihren Ansprechpartner.',
	409: 'Es gab einen Konflikt. Eine Eingabe ist bereits vergeben.',
	500: 'Ein unerwarteter Fehler ist aufgetreten, bitte kontaktieren Sie Ihren Ansprechpartner',
	501: 'Die Funktion steht aktuell nicht zur Verfügung, bitte kontaktieren Sie Ihren Ansprechpartner!',
	'conflict: vehicle.delete.activeVignettesFound': 'Fahrzeuge mit einer aktiven Vignette können nicht gelöscht werden.',
	'cannot delete root user': 'Der vom System eingerichtete Benutzer, mit der Kundennummer als Benutzername, kann nicht gelöscht werden.',
	'Contact person is already assigned to this training.': 'Der Teilnehmer wurde bereits hinzugefügt.',
	confirmMail: 'Die Bestätigungsmail konnte nicht verschickt werden. Bitte kontaktieren Sie den Administrator.',
	customerIdNotFound: 'Es konnte kein Kunde mit dieser Kundennummer gefunden werden. ',
	dashboardSaveError: 'Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht gespeichert werden.',
	dataLoading:
		'Es konnten keine Daten geladen werden, möglicherweise sind Sie offline. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
	dateInPast: 'Das Datum darf nicht in der Vergangenheit liegen.',
	deleteDashboard: 'Möchten Sie das Dashboard wirklich löschen?',
	deleteWidget: 'Möchten Sie dieses Widget wirklich löschen?',
	deleteWidgetDueSpaceLimitations:
		'Aufgrund von Platzmangel konnte das Widget Ihrem Dashboard leider nicht hinzugefügt werden.</br> Bitte löschen oder verschieben Sie vorhandene Widgets, um Platz für neue zu schaffen.', // This is more like an info
	DEREGISTER__WIZARD: 'Ein Wizard konnte nicht deregistriert werden. Bitte kontaktieren Sie Ihren Administrator.',
	downloadFile: 'Bitte laden Sie die Datei herunter, bevor Sie fortfahren können.',
	fileUploaderAlreadyUploaded: 'Die Datei wurde bereits hochgeladen.',
	fileUploaderToBig: 'Die Datei ist zu groß, bitte wählen Sie eine kleinere Datei aus.',
	fileUploaderWrongType: 'Dieser Dateityp ist nicht erlaubt.',
	fileUploadFailed: 'Die Datei konnte nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
	vehicleRegistrationUploadFailed: 'Das Hochladen des Fahrzeugscheins ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
	findTask:
		'Der Datensatz zu der von Ihnen ausgewählten Aufgabe konnte nicht gefunden werden. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre It Abteilung.',
	FILE_TO_BIG: 'Es dürfen nur Dateien mit weniger als 5 MB hochgeladen werden.',
	FILE_WRONG_TYPE: 'Es dürfen nur PDF Dateien hochgeladen werden.',
	format: 'Das Format ist nicht korrekt.',
	initError: 'Es gab ein Problem bei der Initialisierung der App. Bitte versuchen Sie es erneut.',
	INVALID_BOX_ACTION: 'Die ausgewählte Aktion steht gerade nicht zur Verfügung.',
	max: 'Der eingegebene Wert is zu groß',
	maxlength: 'Die Eingabe ist zu lang. Bitte verwenden Sie weniger Zeichen.',
	min: 'Der eingegebene Wert is zu klein',
	minlength: 'Die Eingabe ist zu kurz. Bitte verwenden Sie mehr Zeichen.',
	nothingHappened: 'Nichts ist passiert.',
	OPEN_WIZARD: 'Es konnte kein Wizard zur Bearbeitung der Aufgabe gefunden werden. Bitte kontaktieren Sie Ihren Administrator.',
	pattern: 'Bitte füllen Sie die Felder korrekt aus.',
	priceRequest: 'Die Preise konnten nicht abgerufen werden. Bitte kontaktieren Sie den Administrator.',
	processNextFailed:
		'Der Prozess befindet sich in einem validen Status, `next` würde lediglich einen invaliden Status in den nästen Überführen',
	REGISTER__WIZARD: 'Ein Wizard konnte nicht registriert werden. Bitte kontaktieren Sie Ihren Administrator.',
	required: 'Pflichtfelder müssen ausgefüllt werden.',
	resetDashboard:
		'Möchten Sie dieses Dashboard auf Standardeinstellungen zurücksetzen?</br>Das Zurücksetzen wird nicht automatisch gespeichert und kann im Nachhinein abgebrochen werden.',
	saveDashboard: 'Möchten Sie die Änderungen an diesem Dashboard speichern?',
	sepaMandateReactivate409:
		'Das SEPA-Mandat konnte nicht reaktiviert werden, da keine Unterschrift vorhanden ist. Bitte legen Sie stattdessen ein neues SEPA-Mandat an.',
	softResetDashboard: 'Möchten Sie die Änderungen verwerfen?',
	solveTask: 'Möchten Sie die Aufgabe wirklich schließen?',
	USER_FIELDS_MISSING: 'Der Nutzer kann nicht freigeschaltet werden, da benötigte Felder nicht vorhanden sind.',
	validation: 'Bitte prüfen Sie die angegebenen Daten. Hier scheint es einen Fehler zu geben.',
	vignetteCanceled: 'Die Vignette konnte nicht storniert werden. Bitte kontaktieren Sie den Administrator.',
	[ErrorMessageKey.NO_PROCESS_WIZARD_MATCH]:
		'Es konnte keine Wizard gefunden werden, der den Prozessschritt abarbeiten kann. Bitte kontaktieren Sie Ihren Ansprechpartner.',
	WIZARD_CONTAINER_FETCH:
		'Die erforderlichen Daten konnten nicht (vollständig) geladen werden. Bitte kontaktieren Sie Ihren Ansprechpartner.',
	WIZARD_SCOPE_UNAVAILABLE: 'Es gab ein Problem mit den Wizards (bearbeiten von Aufgaben). Bitte kontaktieren Sie Ihren Administrator.',
	offline: 'Offline: Es kann keine Verbindung mit dem Internet hergestellt werden.',
	[ErrorMessageKey.AUTH_NO_ACTIVITIES]:
		'Es scheint als hätten Sie nicht die notwendigen Berechtigungen, bitte wenden Sie sich an <a href="mailto:helpdesk@svg.de">helpdesk@svg.de</a>.',
	[ErrorMessageKey.NO_ACCESSIBLE_SVGS]: 'Es gab einen Fehler oder Sie haben keine SVG-Zugriffsberechtigungen.',
	[SafetyErrorType.BRIEF_REPORT_NOT_AVAILABLE]: 'Es kann keine Kurzauskunft für diesen Kunden angefragt werden.',
	[SafetyErrorType.INCIDENT_IS_NOT_SAFETY]: 'Die Sicherheit is nicht mit diesem Vorgang kompatibel.',
	[SafetyErrorType.INCIDENT_IS_NOT_SUGGESTION]: 'Der Vorfall ist nicht vom Typ Vorschlag.',
	[SafetyErrorType.INCONSISTEND_REQUEST_DATA]: 'Die Anfrage is ungültig.',
	[SafetyErrorType.NO_CREFO_NUMBER_AVAILABLE]: 'Für den Kunden existiert keine Creditreform Nummer.',
	[SafetyErrorType.SAFETY_CANNOT_BE_MODIFIED]: 'Die Sicherheit kann nicht editiert werden.',
	[SafetyErrorType.SAFETY_IS_NOT_RV_TYPE]: 'Die Sicherheit ist nicht vom Typ R+V.',
	[ErrorMessageKey.AUTH_CUSTOMER_FORBIDDEN]:
		'Sie haben nicht die nötigen Berechtigungen, um den Kunden zu laden, bitte kontaktieren Sie Ihren Ansprechpartner für weitere Details.',
	[ErrorMessageKey.AUTH_CUSTOMER_NOT_FOUND]:
		'Der von Ihnen ausgewählte Kunde existiert nicht im System, bitte kontaktieren Sie Ihren Ansprechpartner für weitere Details.',
	[ErrorMessageKey.SCOPE_OF_CARE_NOT_NAVIGABLE]: 'Die Gefährdungsbeurteilung kann nicht geöffnet werden.',
	[ErrorMessageKey.USER_ADMIN_NOT_NAVIGABLE]: 'Die Details des Benutzers können nicht geöffnet werden.',
	[ErrorMessageKey.MISSING_RISK_ASSESSMENT_ID_FOR_DEFINING_DEFAULT]:
		'Die Id dieser GfB ist nicht valide. Bitte kontaktieren Sie Ihren Administrator!',
	[ErrorMessageKey.MISSING_VEHICLE_ON_FLEX_BOX]:
		'Die Box ist keinem Fahrzeug zugewiesen. Bitte Sprechen Sie mit Ihrem Ansprechpartner, um die Daten der Box manuell zu validieren.',
	cstmBillableServiceTimeParamsMissing: 'Die Zeit kann erst validiert werden, wenn die Felder Sifa, Kategorie und Datum gesetzt sind.',
	cstmMissingCheckBox: 'Bitte wählen Sie mindestens eine Option aus.',
	cstmMustNotMatch: 'Die Eingabe ist nicht valide.',
	cstmAlreadyParticipating: 'Die Kontaktperson wurde bereits hinzugefügt.',
	cstmCustomerAlreadyTaken: 'Der Kunde wurde bereits hinzugefügt.',
	cstmNotLastYearExceptItsJanuary: 'Das Datum darf nur im letzten Jahr liegen, wenn Januar ist.',
	cstmRegulationAlreadyAdded: 'Die ausgewählte Vorschrift wurde bereits hinzugefügt.',
	cstmToMuchWorkingHours: 'Die Arbeitszeit von 12h pro Tag darf nicht überschritten werden.',
	[ErrorMessageKey.MISSING_PORTAL_UID]: 'Unvollständiger Datensatz des Kunden. Kontaktieren Sie Ihren Ansprechpartner.',
	default: DEFAULT_ERROR_MESSAGE,
};

const ENRICHED_MESSAGES: EnrichedErrorMessagesMapping = {
	deleteMultipleUser: {
		txt: `Möchten Sie <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> User wirklich löschen?`,
		keys: [],
	},
	deleteMultipleVehicle: {
		txt: `Möchten Sie <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> Fahrzeuge wirklich löschen?`,
		keys: [],
	},
	deleteMultipleRegistration: {
		txt: `Möchten Sie <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> Registrierungen wirklich löschen?`,
		keys: [],
	},
	deleteMultipleVehicleCategory: {
		txt: `Möchten Sie die <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> Kategorien wirklich löschen?`,
		keys: [],
	},
	deleteUser: {
		txt: `Möchten Sie den User <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> wirklich löschen?`,
		keys: ['username', 'firstName', 'lastName'],
	},
	deleteVehicle: {
		txt: `Möchten Sie das Fahrzeug <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> wirklich löschen?`,
		keys: ['licensePlate', 'description'],
	},
	deleteVehicleCategory: {
		txt: `Möchten Sie die Kategorie <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> wirklich löschen?`,
		keys: ['description'],
	},
	deleteRegistration: {
		txt: `Möchten Sie die Registrierung <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> wirklich löschen?`,
		keys: ['id'],
	},
	delete: { txt: 'Möchten Sie <strong>' + ENRICHED_ERROR_PLACEHOLDER + '</strong> wirklich löschen?', keys: [] },
	solveTask: {
		txt: `Möchten Sie diese Aufgabe wirklich schließen?<br/><strong>"${ENRICHED_ERROR_PLACEHOLDER}"</strong>`,
		keys: ['message'],
	},
	solveTasks: {
		txt: `Möchten Sie <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> Aufgaben wirklich schließen?`,
		keys: [],
	},
	bulkUpdateEmployeePermissions: {
		txt: `Möchten Sie wirklich die Benutzer <strong>${ENRICHED_ERROR_PLACEHOLDER}</strong> wirklich aktualisieren?`,
		keys: ['username'],
	},
	REGISTER__WIZARD: {
		txt: `Der Wizard zur Aufgabe ${ENRICHED_ERROR_PLACEHOLDER} konnte nicht registriert werden. Bitte kontaktieren Sie Ihren Administrator.`,
		keys: [], // no registeredKeys results in json stringify obj
	},
	OPEN_WIZARD: {
		txt: `Es konnte kein Wizard zur Bearbeitung der Aufgabe ${ENRICHED_ERROR_PLACEHOLDER} gefunden werden. Bitte kontaktieren Sie Ihren Administrator.`,
		keys: ['wizardKey', 'scopeKey'],
	},
	validation: {
		txt: `Bitte prüfen Sie die angegebenen Daten. Das Feld ${ENRICHED_ERROR_PLACEHOLDER} wurde vom Server abgelehnt.`,
		keys: [],
	},
	403: { txt: `Fehlende Authorisierung (${ENRICHED_ERROR_PLACEHOLDER})`, keys: ['activity'] },
	409: { txt: `Diese Felder sind bereits vergeben: ${ENRICHED_ERROR_PLACEHOLDER}`, keys: [] },
	404: { txt: `${ENRICHED_ERROR_PLACEHOLDER} konnte nicht gefunden werden.`, keys: [] },
	default: { txt: 'Es ist ein Fehler aufgetreten - bitte prüfen Sie Ihre Eingaben.', keys: [] },
};

@Injectable({ providedIn: 'root' })
export class SvgErrorMessageFactoryService extends ErrorMessageFactoryService {
	constructor() {
		super(MESSAGES, ENRICHED_MESSAGES);
	}
}
