import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
	CustomerLogoControllerService,
	DocumentDto,
	DocumentLinkDto,
	DocumentMetadataDto,
	DocumentsArchiveControllerService,
	DocumentsControllerService,
} from '@myasi/api/document';

import { AsiApiBaseUrls, Resp } from '@mysvg/utils';
import { ErrorHandlingService, ErrorHandlingType } from '@svg-frontends/error';

import { API_BASE_URLS_TOKEN } from '@svg/environment';

export interface uploadDocumentParams {
	customerId?: number;
	documentTypeId?: number;
	mediaType?: string;
	name?: string;
	fieldOfCareId?: number;
	author?: string;
	version?: number;
	isPortalReleased?: boolean;
	template?: boolean;
	asdTemplate?: boolean;
	body?: {
		data: Blob;
	};
}

export interface GetDocumentsParams {
	customerId?: number;
	name?: string;
	documentTypeIds?: number[];
	fieldOfCareIds?: number[];
	author?: string;
	uploadDateFromTo?: string;
	version?: number;
	isPortalReleased?: boolean;
	serviceProviderId?: number;
	limit?: number;
	offset?: number;
	sortBy?: string;
	sortOrder?: 'ASC' | 'DESC';
}

@Injectable({ providedIn: 'root' })
export class DocumentRepositoryService {
	constructor(
		@Inject(API_BASE_URLS_TOKEN) private baseApiUrls: AsiApiBaseUrls,
		private customerLogoControllerService: CustomerLogoControllerService,
		private documentsControllerService: DocumentsControllerService,
		private documentsArchiveControllerService: DocumentsArchiveControllerService,
		private errorHandlingService: ErrorHandlingService,
	) {}

	getLogo(customerId: number): Observable<string[]> {
		return this.customerLogoControllerService.getCustomerLogo({ customerId });
	}

	setLogo(customerId: number, blob: Blob): Observable<any> {
		return this.customerLogoControllerService.setCustomerLogo({ customerId, body: { image: blob } });
	}

	getDocLinkFor(documentMetadataDto: DocumentMetadataDto): Observable<DocumentLinkDto> {
		return this.documentsControllerService.getDocumentLink({ id: documentMetadataDto.id });
	}

	getDocLinkForId(id: number): Observable<DocumentLinkDto> {
		return this.documentsControllerService.getDocumentLink({ id: id });
	}
	/**
	 * Opens a documentLinkDto file reference in a new tab
	 */
	openDocumentLink(documentLinkDto: DocumentLinkDto): void {
		if (documentLinkDto.url) {
			window.open(this.baseApiUrls.document + documentLinkDto.url, '_blank')?.focus();
		}
	}

	/**
	 * Opens/Downloads document no matter which format we have the document in right now
	 * @param document Document in either link or metadata Dto format
	 */
	openDocumentOrDocumentLink(document: DocumentLinkDto | DocumentMetadataDto): void {
		if ('url' in document) {
			// already document link
			this.openDocumentLink(document);
		} else if ('name' in document) {
			// document (metadataDto)
			this.getAndOpenDocUrlFor(document);
		} else {
			this.errorHandlingService.setNextErrorBy(
				new Error('Tried to open unsupported Document object'),
				ErrorHandlingType.SHOW_ERROR_DIALOG,
				true,
			);
		}
	}

	getDocUrlFor(documentMetadataDto: DocumentMetadataDto): Observable<string> {
		return this.getDocLinkFor(documentMetadataDto).pipe(map((response: DocumentLinkDto) => this.baseApiUrls.document + response.url));
	}

	/**
	 * Gets document url for document and opens it in a new tab
	 */
	getAndOpenDocUrlFor(documentMetadataDto: DocumentMetadataDto): void {
		this.getDocUrlFor(documentMetadataDto).subscribe((url: string) => window.open(url, '_blank')?.focus());
	}

	getDocUrlById(id: number): Observable<string> {
		return this.getDocumentMeta(id).pipe(mergeMap((documentMetadataDto: DocumentMetadataDto) => this.getDocUrlFor(documentMetadataDto)));
	}

	getDocumentMeta(id: number): Observable<DocumentMetadataDto> {
		return this.documentsControllerService.getDocument({ id });
	}

	getDocuments(params: GetDocumentsParams): Observable<Resp<DocumentMetadataDto>> {
		return this.documentsControllerService.getDocuments(params);
	}

	getAllGfbDocuments(customerId: number): Observable<DocumentMetadataDto[]> {
		return this.getDocuments({
			customerId,
			documentTypeIds: [2],
			limit: 99999999, // super high limit to get all
			offset: 0, // no offset get all docs
			sortBy: 'uploadDate', // sort by date, latest first, old ones last
			sortOrder: 'DESC',
		}).pipe(map((result: Resp<DocumentMetadataDto>) => result.data));
	}

	archiveDocument(documentId: number): Observable<DocumentDto[]> {
		return this.documentsArchiveControllerService.archiveDocument({ id: documentId });
	}

	uploadDocument(params: uploadDocumentParams): Observable<DocumentMetadataDto> {
		return this.documentsControllerService.uploadDocument(params);
	}
}
