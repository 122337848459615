import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Level, Logger } from '@nsalaun/ng-logger';
import { install as installResizeObserverPolyfill } from 'resize-observer';
import { debounceTime, map } from 'rxjs/operators';

import { DialogLevel, InfoDialogData, StandardDialogService } from '@svg-frontends/dialog';
import { EnrichedError, ErrorHandlingService } from '@svg-frontends/error';
import { GoogleAnalyticsService } from '@svg-frontends/google-analytics';
import { LoggingService } from '@svg-frontends/logging';
import { AuthRefreshService, ContextService, KeycloakTokenClaims, SvgContext } from '@svg-frontends/session';
import { CustomCommonStringsService } from '@svg-frontends/theming';
import { UpdateAppWorkerService } from '@svg-frontends/update-app-worker';

import '../../../../init-dayjs';
import { ENVIRONMENT } from '../environments/environment';
import { ClarityInitService } from './clarity-init.service';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-root',
	styleUrls: ['./app.component.scss'],
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor(
		authRefreshService: AuthRefreshService<SvgContext, KeycloakTokenClaims>,
		clarityInitService: ClarityInitService,
		contextService: ContextService<SvgContext, KeycloakTokenClaims>,
		customCommonStringsService: CustomCommonStringsService,
		googleAnalyticsService: GoogleAnalyticsService,
		loggingService: LoggingService,
		logger: Logger,
		updateAppWorkerService: UpdateAppWorkerService,
		private errorHandlingService: ErrorHandlingService,
		private standardDialogService: StandardDialogService,
	) {
		authRefreshService.init();
		clarityInitService.init();
		contextService.init();
		customCommonStringsService.init();
		googleAnalyticsService.init();
		loggingService.init();
		logger.info(`Started mySVG application and enabled Logging with Level ${Level[logger.level]}`);
		this.initErrorDialogHandling();

		if (ENVIRONMENT.enabledUpdateServiceWorker) {
			updateAppWorkerService.init();
			logger.debug(
				"UpdateAppWorkerService initialized and actively checking for updates in the background to ensure you're always running the latest version.",
			);
		} else {
			logger.debug('Failed to initialize UpdateAppWorkerService. Unable to check for updates in the background');
		}

		// polyfill for older IOS devices not supporting ResizeObserver (WEB-2140)
		if (!window.ResizeObserver) installResizeObserverPolyfill();
	}

	private static getInfoDialogDataFromError(enrichedError: EnrichedError): InfoDialogData {
		return {
			submitButton: 'Ok',
			body: enrichedError.displayMessages.join(' '),
			level: DialogLevel.DANGER,
			title: 'Ups, es ist ein Fehler aufgetreten!',
		};
	}

	private initErrorDialogHandling(): void {
		this.errorHandlingService
			.getErrorForDialogObservable()
			.pipe(
				map((enrichedError: EnrichedError) => AppComponent.getInfoDialogDataFromError(enrichedError)),
				untilDestroyed(this),
				// This is to avoid that one error thrown several times in a short time opening several stacked error dialogs
				// e.g. when network request fails multiple times within a second
				debounceTime(100),
			)
			.subscribe((data: InfoDialogData) => this.standardDialogService.showInfoDialog(data));
	}
}
