export interface Axle {
	label: number;
}

export const AxleEnum: { [key: number]: Axle } = {
	// Axles choices
	2: { label: 2 },
	3: { label: 3 },
	4: { label: 4 },
	5: { label: 5 },
	6: { label: 6 },
	7: { label: 7 },
	8: { label: 8 },
	9: { label: 9 },
};
